exports.getIpLocation = async () => {
  const IpStackEndpoint = new URL("http://api.ipstack.com/check");
  IpStackEndpoint.searchParams.append(
    "access_key",
    process.env.GATSBY_IP_STACK_API_KEY
  );

  const response = await fetch(IpStackEndpoint.href, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
    referrerPolicy: "no-referrer",
  });

  return response.json(); // parses JSON response into native JavaScript objects
};
