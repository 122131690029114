import { useEffect } from "react";
import { getIpLocation } from "../Utils/GeoIpRedirect";
import { useCookies } from "react-cookie";
import { navigate } from "gatsby";

// markup
const IndexPage = () => {
  const [cookies, setCookie] = useCookies(["hevelop-language"]);

  useEffect(() => {
    let language = "en";

    if (cookies["hevelop-language"] !== undefined) {
      navigate(`/${cookies["hevelop-language"]}`);
    } else {
      // Get visitor location via API or browser language
      getIpLocation()
        .then((result) => {
          if (result.country_code !== undefined) {
            // set it if in Italy
            if (result.country_code === "IT") {
              language = "it";
            }
            // store language in a cookie
            setCookie("hevelop-language", language, {
              path: "/",
              maxAge: 2592000,
            });
            // redirect to language path
            navigate(`/${language}`);
          }
        })
        .catch(() => {
          const browserLanguage = navigator.language || navigator.userLanguage;
          if (browserLanguage !== undefined) {
            // set it if in Italy
            if (browserLanguage.includes("it")) {
              language = "it";
            }
            // store language in a cookie
            setCookie("hevelop-language", language, {
              path: "/",
              maxAge: 2592000,
            });
            // redirect to language path
            navigate(`/${language}`);
          }
        });
    }
  });

  return null;
};

export default IndexPage;
